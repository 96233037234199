import React from "react";
import { ProductHeroProps } from "../Hero";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import { Link } from "@ryerson/frontend.navigation";
import { ContentfulProduct, ContentfulGeneralReference } from "../Types/Types";

const HeaderDiv = styled.div`
	margin-top: 68px;
	margin-bottom: 55px;
`;

const ReferencesDiv = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkerGray};
			padding: 13px 45px 7px;
		`;
	}}
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 84px;
`;

const ProductsDiv = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.white};
			padding: 10px 20px 0px;
		`;
	}}
`;

type ProductsLineType = {
	product: ContentfulProduct;
};

const ProductsLine: React.FC<ProductsLineType> = ({ product }) => {
	const { theme } = useTheme();
	return (
		<Link to={product.linkToProduct} gatsby={false}>
			<Flex
				css={css`
					padding-bottom: 20px;
				`}
			>
				<FlexItem>
					<div
						css={css`
							display: inline-block;
							width: 60px;
							height: 60px;
							background-image: url(${product.productRendering.file.url});
							background-repeat: no-repeat;
							background-position: center center;
							background-size: contain;
						`}
					></div>
				</FlexItem>
				<FlexItem
					alignSelf="center"
					css={css`
						padding-left: 20px;
					`}
				>
					<Typography variant="p" color={theme.colors.primary.label} weight="bold">
						{product.productName}
					</Typography>
				</FlexItem>
			</Flex>
		</Link>
	);
};

const ProductsHeroDesktop: React.FC<ProductHeroProps> = ({ staticContent, dynamicContent }) => {
	const { theme } = useTheme();

	//split products for the two product columns
	let allProducts = dynamicContent.products;
	let productsSplit = Math.ceil(allProducts.length / 2);
	let productsBlockOne = allProducts.slice(0, productsSplit);
	let productsBlockTwo = allProducts.slice(productsSplit);

	return (
		<>
			<ContentSection type="tertiary">
				<HeaderDiv>
					<Flex alignContent="space-between" justifyContent="space-between">
						<FlexItem>
							<Typography
								type="tertiary"
								variant="h1"
								css={css`
									font-size: 75px;
								`}
							>
								{dynamicContent.title}
							</Typography>
						</FlexItem>
						<FlexItem>
							<Link to={staticContent.buttonActionUrl} gatsby={false}>
								<Button
									size="lg"
									type="secondary"
									label={staticContent.buttonLabel}
									onClick={() => {}}
								></Button>
							</Link>
						</FlexItem>
					</Flex>
				</HeaderDiv>
				<Typography
					type="tertiary"
					variant="p"
					size="lg"
					css={css`
						margin-bottom: 62px;
						max-width: 640px;
					`}
				>
					{dynamicContent.blurb.blurb}
				</Typography>
				<Flex alignContent="space-between" justifyContent="space-between">
					<FlexItem
						grow={1}
						css={css`
							width: 720px;
							max-width: 720px;
						`}
					>
						<Typography
							variant="h3"
							type="tertiary"
							css={css`
								margin-bottom: 30px;
							`}
						>
							{staticContent.productsLabel}
						</Typography>
					</FlexItem>
					<FlexItem
						grow={1}
						css={css`
							width: 360px;
							max-width: 360px;
						`}
					>
						<Typography
							variant="h3"
							type="tertiary"
							css={css`
								margin-bottom: 30px;
							`}
						>
							{staticContent.referenceTitle}
						</Typography>
					</FlexItem>
				</Flex>
				<Flex alignContent="space-between" justifyContent="space-between">
					<FlexItem
						grow={1}
						css={css`
							width: 360px;
							max-width: 360px;
						`}
					>
						<ProductsDiv theme={theme}>
							{productsBlockOne.map(function (
								product: ContentfulProduct,
								index: number
							) {
								return <ProductsLine key={index} product={product} />;
							})}
						</ProductsDiv>
					</FlexItem>
					<FlexItem
						grow={1}
						css={css`
							width: 360px;
							max-width: 360px;
						`}
					>
						<ProductsDiv theme={theme}>
							{productsBlockTwo.map(function (
								product: ContentfulProduct,
								index: number
							) {
								return (
									<React.Fragment key={index}>
										<ProductsLine key={index} product={product} />
										{index === productsBlockTwo.length - 1 &&
											productsBlockTwo.length < productsBlockOne.length && (
												<Spacer />
											)}
									</React.Fragment>
								);
							})}
						</ProductsDiv>
					</FlexItem>
					<FlexItem
						grow={1}
						css={css`
							width: 360px;
							max-width: 360px;
						`}
					>
						<ReferencesDiv theme={theme}>
							{dynamicContent.references.map(
								(ref: ContentfulGeneralReference, index: number) => {
									return (
										<Link key={index} to={ref.pdf.file.url} gatsby={false}>
											<Typography
												variant="p"
												type="tertiary"
												size="md"
												color={theme.colors.primary.white}
												css={css`
													margin-bottom: 30px;
												`}
											>
												<Icon
													icon="file-pdf"
													css={css`
														vertical-align: middle;
														margin-right: 11px;
													`}
												/>
												{ref.pdf.title}
											</Typography>
										</Link>
									);
								}
							)}
						</ReferencesDiv>
					</FlexItem>
				</Flex>
				{dynamicContent.blurb2 && (
					<Typography
						type="tertiary"
						variant="p"
						css={css`
							max-width: 744px;
							margin-top: 56px;
							margin-bottom: 60px;
						`}
					>
						{dynamicContent.blurb2.blurb2}
					</Typography>
				)}
			</ContentSection>
		</>
	);
};

export default ProductsHeroDesktop;
